<template>
  <div class="shop_main">
    <div class="shop_search_menu">
      <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
      <i class="el-icon-arrow-right"></i>
      <a href="javascript:void(0)">三方质检</a>
    </div>

    <img :src="'./img/banner_quality_jianxun@3x.png'" class="page_banner" />
    <div class="shop_quality">
      <div class="shop_quality_title">
        <img class="guolian_title_img1" :src="'./img/guolian_title1.png'" alt="">
      </div>

      <div class="shop_quality_intro">
        <p>广东省检迅检测科技有限公司是一家以化妆品、食品、农产品、保健品、环境为主的综合性第三方检测机构。依据IS0/IEC17025要求建立完善的质量管理体系。公司位于东莞长安，具有6000平米区域，全国范围有多个分支公司及办事处。主要开展化妆品及日用产品的检测、食品及农产品的检测、消字号及一次性卫生用品的检测、生产及生活用水的检测、保健品检测等。同时，检迅检测也开展国产非特殊化妆品备案检验，学校食堂、公众餐饮及连锁品牌的一站式食品安全监管服务，食品生产许可服务（SC认证）。</p>
      </div>

      <div class="shop_quality_title">
        <img class="guolian_title_img2" :src="'./img/guolian_title2.png'" alt="">
      </div>

      <div class="shop_quality_certificate">
        <img class="mt20" :src="'./img/jianxun_certificate_img01.png'" alt="">
        <img :src="'./img/jianxun_certificate_img02.png'" alt="">
        <img class="mt20" :src="'./img/jianxun_certificate_img03.png'" alt="">
      </div>

      <div class="shop_quality_title">
        <img class="title_img4" :src="'./img/guolian_title3.png'" alt="">
      </div>

      <div class="shop_quality_example blueExample shop_quality_example_jianxun" :style="{'background-image': 'url(./img/jianxun_img11.png)'}">
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/guolian_img10.png'" alt="">
          <p class="fontBlue">中药材</p>
        </div>
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/guolian_img11.png'" alt="">
          <p class="fontBlue">农产品</p>
        </div>
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/jianxun_img01.png'" alt="">
          <p class="fontBlue">保健品</p>
        </div>
        <div class="example borderBottom borderRight">
          <img class="mt11" :src="'./img/jianxun_img02.png'" alt="">
          <p class="fontBlue">化妆品</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/guolian_img14.png'" alt="">
          <p class="fontBlue">食品</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/jianxun_img03.png'" alt="">
          <p class="fontBlue">饮用水</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/jianxun_img04.png'" alt="">
          <p class="fontBlue">环境</p>
        </div>
        <div class="example borderRight">
          <img class="mt35" :src="'./img/guolian_img17.png'" alt="">
          <p class="'fontBlue">其他</p>
        </div>
      </div>

      <div class="shop_quality_title">
        <img class="guolian_title_img3" :src="'./img/guolian_title4.png'" alt="">
      </div>

      <div class="jianxun_environment">

        <p class="environment_text jianxun_environment_text" :style="{'background-image': 'url(./img/jianxun_img05.png)'}">
          公司现有大型设备包括电感耦合等离子质谱（ICPMS)、电感耦合等离子体发射仪（ICP-OES）、液相色谱串联质谱仪（LC-MSMS)、气相色谱质谱仪（GCMS）、高效液相色谱仪（HPLC)、气相色谱仪（GC)、原子吸收分光光度计（AAS）、原子荧光光度计（AFS)、液相色谱串联原子荧光光度计（LC-AFS）、红外测油仪、紫外一可见分光光度计等，满足食品、化妆品、农产品、保健品、消毒产品、水质及环境等领域检测需求。
        </p>

        <div class="jianxun_environment_imggroup mt40">
          <img class="mr20" :src="'./img/jianxun_img06.png'" alt="">
          <img class="mr20" :src="'./img/jianxun_img07.png'" alt="">
          <img :src="'./img/jianxun_img08.png'" alt="">
          <img class="mr20" :src="'./img/jianxun_img09.png'" alt="">
          <img :src="'./img/jianxun_img10.png'" alt="">
        </div>
      </div>

      <Contract :type="3"></Contract>
    </div>
  </div>
</template>

<script>
const Contract = () => import('./contract');
export default {
  name: "jianXun",
  created() {
    this.$store.commit('changeScrollTop');
  },
  components: {
    Contract
  }
}
</script>

<style scoped>

</style>
